import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import Layout from '../../components/Layout';
import ContentContainer from '../../styles/content-container';
import FeaturedCard from '../../components/FeaturedCard';
import theme from '../../styles/theme';
import { SEO } from '../../components/Seo';
export default function Video({ data }) {
  const { edges: latestVideos } = data.allMarkdownRemark;

  return (
    <Layout>
      <ContentContainer
        sx={{
          marginBottom: '6%',
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexFlow: 'wrap',
          },
          [theme.breakpoints.down('sm')]: { display: 'block' },
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: '6%', width: '100%' }}>
          Videos
        </Typography>
        {latestVideos &&
          latestVideos.length > 1 &&
          latestVideos.map((video) => (
            <FeaturedCard
              href={`/video/${video.node.frontmatter.path}`}
              title={video.node.frontmatter.title}
              summary={video.node.frontmatter.summary_text}
              src={video.node.frontmatter.summary_image}
              alt="Summary"
              sx={{
                [theme.breakpoints.up('sm')]: { width: '48%' },
                [theme.breakpoints.down('sm')]: { width: '100%' },
              }}
            />
          ))}
      </ContentContainer>
    </Layout>
  );
}

export const Head = () => <SEO />;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "video" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            summary_text
            summary_image
          }
        }
      }
    }
  }
`;
